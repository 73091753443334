input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}

/* === Body Scrollbar === */
body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
body::-webkit-scrollbar-corner {
    background-color: transparent;
}
body::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0,0,0,.15);
    -webkit-box-shadow: inset -1px -1px 0 rgba(0,0,0,.05), inset 1px 1px 0 rgba(0,0,0,.05);
}

.menu_left::-webkit-scrollbar {
    width:4px;
}
.menu_left::-webkit-scrollbar-thumb {
    height: 50px;
    background: hsla(0, 0%, 53.3%, 0.4);
}

.topBuyerFrame{
    border-image-slice: 42 42 42 42;
    border-image-width: 42px 42px 42px 42px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: round round;
    border-image-source: url("https://likeaura.com/images/cr-area.png");;
    border-style: solid;
}
.topBuyerFrameRank{
    margin: auto;
    display: flex;
    height: 35px;
    background-color: #f27120;
    border-radius: 50%;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    width: 35px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    border: 3px solid #fff;
    box-shadow: 0px 10px 30px 0px rgb(27 20 100 / 10%);
}
.topBuyerFrameRank::before {
    margin-right: 0px;
    content: '';
    background: url("https://likeaura.com/images/crwon.png");
    position: absolute;
    background-repeat: no-repeat;
    top: -16px;
    width: 26px;
    height: 24px;
    background-size: contain;
}

.profileBanner{
    padding: 1rem;
    display: flex;
    background-color: #fff;
    border-radius: 1rem;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem
}

.profile-banner-details{
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    flex-wrap: wrap;
}

.downline_visitedID:not(:last-child)::after{
    content: " > ";
}
.downline_visitedID:hover{
    color: blue;
    cursor: pointer;
}

.ImgDivGrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
}

.formFieldLbel{
    text-align: center;
}

.profilePicEditWrapper{
    position: relative;
    cursor: pointer;
}
.profilePicEditWrapperPencil{
    position: absolute;
    bottom: 0;
    right: 0;
}
.userInfoImg{
    height: 100px;
    width: 100px;
}
.userLongList{
    padding: 1rem;
}


@media screen and (max-width: 600px) {
    .ImgDivGrid{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    .profile-banner-details{
        flex-direction: column;
    }
    .containerMobile{
        width: 100% !important;
        height: auto !important;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .signatureMobile{
        min-width: 100px;
        max-height: 80px !important
    }
    .gridItemMobile{
        min-width: 170px !important;
    }
    .griddItemMobile {
        margin: auto;
    }
}